import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BallOfCoinsBackground from './BallOfCoinsBackground';
import { MenuItem, Select, Button, FormControl, InputLabel, Box } from '@mui/material';
import '../styles/Home.css'; // Ensure you have a CSS file for the styles

// Import coin data and logos
import coinsData from '../assets/listed_coins.json'; // Adjust the path as needed
import logos from './logos'; // Adjust the path as needed

const Home = () => {
  const [selectedCoin, setSelectedCoin] = useState('WEN'); // Set default coin to "WEN"
  const history = useHistory();

  const handleCoinChange = (event) => {
    console.log("Selected Coin:", event.target.value);
    setSelectedCoin(event.target.value);
  };

  const handleEnterClick = () => {
    if (selectedCoin) {
      console.log("Navigating to:", `/charts/${selectedCoin}`);
      history.push(`/charts/${selectedCoin}`);
    } else {
      alert('Please select a coin');
    }
  };

  return (
    <div className="home-container">
      <div className="background-animation">
        <BallOfCoinsBackground />
      </div>
      <div className="content-container">
        <div className="text-container">
          <h1 className="glossy-text">Choose your coin</h1>
          <div className="form-container">
            <FormControl variant="outlined" className="form-control">
              <InputLabel className="form-control-label"></InputLabel>
              <Select
                value={selectedCoin}
                onChange={handleCoinChange}
                label=""
                className="select-menu"
                MenuProps={{
                  PaperProps: {
                    className: 'select-menu-paper',
                  },
                }}
              >
                {Object.keys(coinsData.coins).map((coinKey) => {
                  const coin = coinsData.coins[coinKey];
                  const cleanCoin = coinKey.includes('$') ? coinKey.replace('$', '') : coinKey;
                  const logo = logos[cleanCoin] || 'default-logo.png'; // Use default logo if not found
                  return (
                    <MenuItem key={coinKey} value={coinKey} className="select-menu-item">
                      <Box display="flex" alignItems="center">
                        <img 
                          src={logo} 
                          alt={coinKey} 
                          className="coin-logo"
                          onError={(e) => { e.target.onerror = null; e.target.src="default-logo.png"; }} // Handle broken image links
                        />
                        <span>{cleanCoin}</span>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" className="enter-button" onClick={handleEnterClick}>
              Enter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
