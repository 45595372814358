import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client'; // Import Socket.IO client
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { formatPrice } from '../../utils/formatPrice'; // Ensure this is the correct path
import './styles/MyLastTrades.css'; // Import the CSS file
import { getSocket } from '../websocket'; 

const MyLastTrades = ({ userId, coin }) => { // Accept userId and coin as props
  const [data, setData] = useState([]);
  
  // Dynamically create the column headers using the coin variable
  const columns = [
    'TIME',
    'ACTION',
    `QTY (${coin})`,
    `PRICE`,
  ];
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const fetchData = () => {
    axios.get(`${backendUrl}/api/data/my_last_trades`, { params: { userId, coin } })
      .then(response => setData(response.data))
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchData();
    const socket = getSocket();

    // WebSocket listener for real-time updates
    const handleUpdate = (message) => {
      if (message.key === `trades:${coin}:${userId}`) {
        console.log(`Received update for last trades of user ${userId} for ${coin}, refreshing data...`);
        fetchData(); // Re-fetch data when trades are updated
      }
    };

    socket.on('update', handleUpdate);
    return () => {
      socket.off('update', handleUpdate); // Cleanup WebSocket connection when component unmounts
    };
  }, [userId, coin]); // Re-fetch data when userId or coin changes

  return (
    <Box className="my-last-trades-container">
      <TableContainer component={Paper} className="my-last-trades-table-container">
        <Table stickyHeader className="my-last-trades-table">
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell key={index} className="my-last-trades-table-head-cell">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="my-last-trades-table-row">
                {columns.map((key, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={`my-last-trades-table-cell ${key === 'ACTION' && (row[key] === 'BUY' ? 'my-last-trades-buy-cell' : row[key] === 'SELL' ? 'my-last-trades-sell-cell' : '')}`}
                  >
                    {key === `QTY (${coin})` || key === `PRICE`
                      ? formatPrice(row[key])
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MyLastTrades;
