const logos = {
    WEN: require('../assets/logos/WEN.png').default,
    SILK: require('../assets/logos/SILK.png').default,
    ETIME: require('../assets/logos/ETIME.png').default,
    GVGX: require('../assets/logos/GVGX.png').default,
    GSWAP: require('../assets/logos/GSWAP.png').default,
    GSUPER: require('../assets/logos/GSUPER.png').default,
    KEN: require('../assets/logos/KEN.png').default,
    Materium: require('../assets/logos/Materium.png').default,
    GMUSIC: require('../assets/logos/GMUSIC.png').default,
    TOLK: require('../assets/logos/TOLK.png').default,
    ADT: require('../assets/logos/ADT.png').default,
    DJT: require('../assets/logos/DJT.png').default,
    MAG: require('../assets/logos/MAG.png').default,
    AGBT: require('../assets/logos/AGBT.png').default,
    FARMER: require('../assets/logos/FARMER.png').default,
    TUPAC: require('../assets/logos/TUPAC.png').default,
    FUND: require('../assets/logos/FUND.png').default,
    JESUS: require('../assets/logos/JESUS.png').default,
    HAWKTUAH: require('../assets/logos/HAWKTUAH.png').default,
    SOON: require('../assets/logos/SOON.png').default,
    CHINA: require('../assets/logos/CHINA.png').default,
    MOUM: require('../assets/logos/MOUM.png').default,
    JAUM: require('../assets/logos/JAUM.png').default,
    RUSSIA: require('../assets/logos/RUSSIA.png').default,
    DM: require('../assets/logos/DM.png').default,
    BEEF: require('../assets/logos/BEEF.png').default,
    SNOOP: require('../assets/logos/SNOOP.png').default,
    USA: require('../assets/logos/USA.png').default,
    CWC: require('../assets/logos/CWC.png').default,
    TVB: require('../assets/logos/TVB.png').default,
    BEATZ: require('../assets/logos/BEATZ.png').default,
    SQUIDLPS: require('../assets/logos/SQUIDLPS.png').default,
    CRYPT: require('../assets/logos/CRYPT.png').default,
    LUMN: require('../assets/logos/LUMN.png').default,
    ALPHAQ: require('../assets/logos/ALPHAQ.png').default,
    TWIN: require('../assets/logos/TWIN.png').default,
    STAYFREE: require('../assets/logos/STAYFREE.png').default,
    CCF: require('../assets/logos/CCF.png').default,
    ROI: require('../assets/logos/ROI.png').default,
    WENGAME: require('../assets/logos/WENGAME.png').default,
    HAWKTUAH: require('../assets/logos/HAWKTUAH.png').default,
    NDCX: require('../assets/logos/NDCX.png').default,
    TIP: require('../assets/logos/TIP.png').default,
    RONPAUL: require('../assets/logos/RONPAUL.png').default,
    MRVS: require('../assets/logos/MRVS.png').default,
    REPS: require('../assets/logos/REPS.png').default,
    TIPPED: require('../assets/logos/TIPPED.png').default,
    HUAHUA: require('../assets/logos/HUAHUA.png').default,
    SERVE: require('../assets/logos/SERVE.png').default,
    BENE: require('../assets/logos/BENE.png').default,
    METAL: require('../assets/logos/METAL.png').default,
    PITQC: require('../assets/logos/PITQC.png').default,
    GLD: require('../assets/logos/GLD.png').default,
    SAFEG: require('../assets/logos/SAFEG.png').default,
    FBB: require('../assets/logos/FBB.png').default,
    GPEPE: require('../assets/logos/GPEPE.png').default,
    TENDIES: require('../assets/logos/TENDIES.png').default,
    SVR: require('../assets/logos/SVR.png').default,
    RFKJ: require('../assets/logos/RFKJ.png').default,
    ROIBOI: require('../assets/logos/ROIBOI.png').default,
    GIBBY: require('../assets/logos/GIBBY.png').default,
    TACO: require('../assets/logos/TACO.png').default,
    HG: require('../assets/logos/default-logo.png').default,
    THOON: require('../assets/logos/THOON.png').default,
    SUBSPACE: require('../assets/logos/SUBSPACE.png').default,
    TORY: require('../assets/logos/TORY.png').default,
    CASH: require('../assets/logos/CASH.png').default,
    TRUMP: require('../assets/logos/TRUMP.png').default,
    WOOF: require('../assets/logos/WOOF.png').default,
    BB: require('../assets/logos/BB.png').default,
    QACOIN: require('../assets/logos/QACOIN.png').default,
    GROCK: require('../assets/logos/GROCK.png').default,
    EPXStamina: require('../assets/logos/default-logo.png').default,
    GELS: require('../assets/logos/GELS.png').default,
    POPCORN: require('../assets/logos/default-logo.png').default,
    GalaChips: require('../assets/logos/default-logo.png').default,
    GWBTC: require('../assets/logos/GWBTC.png').default,
    GWETH: require('../assets/logos/GWETH.png').default,
    GUSDC: require('../assets/logos/GUSDC.png').default,
    GUSDT: require('../assets/logos/GUSDT.png').default,
    TESTGMUSIC: require('../assets/logos/TESTGMUSIC.png').default,
    TOLK: require('../assets/logos/TOLK.png').default,
    HRBR: require('../assets/logos/HRBR.jpg').default
  };
  
  export default logos;
  