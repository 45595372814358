import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import CircularProgress from '@mui/material/CircularProgress';

const BallOfCoinsBackground = () => {
  const mountRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const modelRef = useRef(null);
  const [theme, setTheme] = useState(getCurrentTheme());

  function getCurrentTheme() {
    return document.documentElement.classList.contains('dark') ? 'dark' : 'light';
  }

  useEffect(() => {
    const handleThemeChange = () => {
      setTheme(getCurrentTheme());
    };

    const observer = new MutationObserver(handleThemeChange);
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1200 / 750, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(1200, 750); // Set the size of the canvas
    mountRef.current.appendChild(renderer.domElement);
    renderer.setClearColor(0x000000, 0); // Set to black with 0 opacity

    new RGBELoader().setPath('/assets/') // Path to your environment map folder
      .load('studio_country_hall_1k.hdr', (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = texture;

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.6); // Soft white light
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(5, 5, 5); // Position the light
        scene.add(directionalLight);

        const createCoinMesh = () => {
          const geometry = new THREE.CylinderGeometry(0.05, 0.05, 0.01, 32);
          const material = new THREE.MeshStandardMaterial({
            color: 0xffd700, // Gold color
            metalness: 0.9,
            roughness: 0.1,
            envMap: scene.environment // Add environment map for reflections
          });
          return new THREE.Mesh(geometry, material);
        };

        const radius = 1.9; // Radius of the sphere
        const particlesCount = 1000;

        const coinsGroup = new THREE.Group();

        for (let i = 0; i < particlesCount; i++) {
          const phi = Math.acos(2 * Math.random() - 1);
          const theta = 2 * Math.PI * Math.random();

          const x = radius * Math.sin(phi) * Math.cos(theta);
          const y = radius * Math.sin(phi) * Math.sin(theta);
          const z = radius * Math.cos(phi);

          const coin = createCoinMesh();
          coin.position.set(x, y, z);
          coin.lookAt(0, 0, 0);
          coinsGroup.add(coin);
        }

        scene.add(coinsGroup);

        const loadModel = () => {
          setLoading(true);
          // GLTFLoader to load your GLB model
          const gltfLoader = new GLTFLoader();
          gltfLoader.setPath('/assets/'); // Path to your GLB model folder
          gltfLoader.load(
            theme === 'dark' ? 'gala_logo_3D_dark_mode.glb' : 'gala_logo_3D_2.glb',
            (gltf) => {
              if (modelRef.current) {
                scene.remove(modelRef.current);
              }
              const loadedModel = gltf.scene;
              loadedModel.position.set(0, 0, 0);
              scene.add(loadedModel);
              modelRef.current = loadedModel;
              setLoading(false); // Hide loader once model is loaded
            },
            undefined,
            (error) => {
              console.error('An error occurred loading the GLB model:', error);
              setLoading(false); // Hide loader in case of error
            }
          );
        };

        loadModel();

        camera.position.z = 5;

        let clock = new THREE.Clock();
        const animate = () => {
          requestAnimationFrame(animate);

          // Rotate only the coins group
          coinsGroup.rotation.y += 0.01;

          // Apply floating effect to the model
          if (modelRef.current) {
            const time = clock.getElapsedTime();
            modelRef.current.position.z = 0.1 * Math.sin(time); // Move back and forth along Z
            modelRef.current.rotation.z = 0.02 * Math.sin(time); // Rotate back and forth around Z
            modelRef.current.rotation.y = 0.1 * Math.sin(time); // Rotate back and forth around Z
          }

          renderer.render(scene, camera);
        };

        animate();
      });

    const handleResize = () => {
      renderer.setSize(1200, 750); // Adjust the size of the canvas
      camera.aspect = 1200 / 750;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [theme]);

  return (
    <div ref={mountRef} className="three-container" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading && <CircularProgress style={{ position: 'absolute' }} />}
    </div>
  );
};

export default BallOfCoinsBackground;
