import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Box } from '@mui/material';
import axios from 'axios';

const PieChartHoldings = ({ userId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    // A set of visually appealing and distinct colors
    const COLORS = [
        '#FF6384', '#36A2EB', '#FFCE56', '#8E44AD', '#2ECC71',
        '#E74C3C', '#3498DB', '#F39C12', '#27AE60', '#D35400',
        '#2980B9', '#8E44AD', '#1ABC9C', '#C0392B', '#E67E22'
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/data/my_holdings_usdt?userId=${userId}`);
                const fetchedData = response.data;
                const formattedData = Object.keys(fetchedData)
                    .map((key) => ({
                        name: key,
                        value: fetchedData[key].PriceUsdt,
                    }))
                    .sort((a, b) => b.value - a.value); // Sort by value in descending order
                setData(formattedData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching data: {error.message}</div>;
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <PieChart width={510} height={400}>
                <Pie
                    data={data}
                    cx={200}
                    cy={200}
                    labelLine={false}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend layout="vertical" align="right" verticalAlign="middle" />
            </PieChart>
        </Box>
    );
};

export default PieChartHoldings;
