import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, ThemeProvider, Tabs, Tab, Box, Typography, IconButton, Tooltip } from '@mui/material';
import theme from '../theme';
import Portfolio from './UserPageComponents/Portfolio';
import Trading from './UserPageComponents/Trading';
import UserIdInput from './UserPageComponents/UserIdInput';
import darkWalletIcon from '../assets/icon_wallet_dark.png';
import lightWalletIcon from '../assets/icon_wallet_light.png';
import '../styles/UserPage.css';

const UserBoard = () => {
    const { coin } = useParams();
    const defaultUserId = 'client|65fd892c6090dd5a1f3815a4';
    const defaultCoin = 'WEN';

    const [userId, setUserId] = useState(defaultUserId.split('|')[1]); // Store only the UUID part
    const [value, setValue] = useState(0);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState(coin || defaultCoin);

    useEffect(() => {
        setSelectedCoin(coin);
    }, [coin]);

    useEffect(() => {
        const currentTheme = document.documentElement.classList.contains('dark');
        setIsDarkMode(currentTheme);

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    const updatedTheme = document.documentElement.classList.contains('dark');
                    setIsDarkMode(updatedTheme);
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class'],
        });

        return () => observer.disconnect();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(`client|${userId}`);
        alert('User ID copied to clipboard');
    };

    const handleUserIdSubmit = (inputUserId) => {
        // Check if the input includes the '|' character and split accordingly
        if (inputUserId.includes('|')) {
            setUserId(inputUserId.split('|')[1]); // Split and store only the UUID part
        } else {
            setUserId(inputUserId); // Use the full input as the user ID if no '|' is present
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="user-board">
                <Container className="container">
                    <Box display="flex" alignItems="center" className="user-info">
                        <img src={isDarkMode ? lightWalletIcon : darkWalletIcon} alt="Wallet Icon" className="icon" />
                        <UserIdInput 
                        onSubmit={handleUserIdSubmit} 
                        initialUserId={userId.startsWith('client|') ? userId : `client|${userId}`} />
                    </Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="user board tabs"
                        className="tabs"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab label="Portfolio" />
                        <Tab label="Trading" />
                    </Tabs>
                    {value === 0 && (
                        <Box>
                            <Portfolio userId={userId} />
                        </Box>
                    )}
                    {value === 1 && (
                        <Box>
                            <Trading userId={userId} isVisible={value === 1} coin={selectedCoin} />
                        </Box>
                    )}
                </Container>
            </div>
        </ThemeProvider>
    );
};

export default UserBoard;
