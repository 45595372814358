import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client'; // Import Socket.IO client
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { formatPrice } from '../../utils/formatPrice'; // Adjust the path as needed
import './styles/OrderBookUser.css'; // Import the CSS file
import { getSocket } from '../websocket'; 

const OrderBookUser = ({ coin }) => {
  const [sellData, setSellData] = useState([]);
  const [buyData, setBuyData] = useState([]);
  const [base, setBase] = useState('GALA'); // Set default base
  const [quote, setQuote] = useState(coin); // Set default quote
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Socket.IO client connection


  useEffect(() => {
    // Determine base and quote whenever coin changes
    if (['GUSDT', 'GUSDC', 'GWETH'].includes(coin)) {
      setQuote('GALA');
      setBase(coin);
    } else {
      setQuote(coin);
      setBase('GALA');
    }
  }, [coin]);

  const fetchData = () => {
    axios.get(`${backendUrl}/api/data/orders?coin=${coin}`)
      .then(response => {
        console.log('API Response:', response.data);
        const sellData = response.data.filter(row => row.Action === 'SELL');
        const buyData = response.data.filter(row => row.Action === 'BUY');
        setSellData(sellData);
        setBuyData(buyData);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
    const socket = getSocket();
    // WebSocket listener for real-time updates
    const handleUpdate = (message) => {
      if (message.key.startsWith(`offers:${coin}`)) {
        console.log(`Received update for order book of ${coin}, refreshing data...`);
        fetchData(); // Re-fetch data when order book is updated
      }
    };

    socket.on('update', handleUpdate);

    return () => {
      socket.off('update', handleUpdate); // Cleanup WebSocket connection when component unmounts
    };
  }, [coin]);

  return (
    <Box className="order-book-user-container">
      <TableContainer component={Paper} className="order-book-user-table-container">
        <Table stickyHeader className="order-book-user-table">
          <TableHead>
            <TableRow>
              <TableCell className="order-book-user-table-head-cell">Price ({base})</TableCell>
              <TableCell className="order-book-user-table-head-cell">Qty ({quote})</TableCell>
              <TableCell className="order-book-user-table-head-cell">Qty ({base})</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellData.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="order-book-user-table-row">
                <TableCell className={`order-book-user-table-cell order-book-user-sell-cell`}>{formatPrice(row[`Price (${base})`])}</TableCell>
                <TableCell className="order-book-user-table-cell">{formatPrice(row[`Qty (${quote})`])}</TableCell>
                <TableCell className="order-book-user-table-cell">{formatPrice(row[`Qty (${base})`])}</TableCell>
              </TableRow>
            ))}
            {buyData.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="order-book-user-table-row">
                <TableCell className={`order-book-user-table-cell order-book-user-buy-cell`}>{formatPrice(row[`Price (${base})`])}</TableCell>
                <TableCell className="order-book-user-table-cell">{formatPrice(row[`Qty (${quote})`])}</TableCell>
                <TableCell className="order-book-user-table-cell">{formatPrice(row[`Qty (${base})`])}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderBookUser;
