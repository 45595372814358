import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import UserBoard from './components/UserPage'; // Corrected import path

localStorage.debug = '*';

const App = () => {
  const [mode, setMode] = useState('dark');

  useEffect(() => {
  if (mode === 'dark') {
    document.documentElement.classList.add('dark');
    document.documentElement.classList.remove('light');
  } else {
    document.documentElement.classList.add('light');
    document.documentElement.classList.remove('dark');
  }
}, [mode]);

  // const theme = useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode,
  //       },
  //     }),
  //   [mode],
  // );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    // <ThemeProvider theme={theme}>
      // <CssBaseline />
      <Router>
        <div>
          <Navbar toggleTheme={toggleTheme} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/charts/:coin" component={Dashboard} />
            <Route path="/user/:coin" component={UserBoard} />
          </Switch>
        </div>
      </Router>
    // </ThemeProvider>
  );
};

export default App;