import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import coinsData from '../assets/listed_coins.json'; // Adjust the path as needed
import logos from './logos'; // Adjust the path as needed

const CoinSelectionMenu = ({ selectedCoin, handleCoinChange }) => {
  const history = useHistory();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check the initial theme based on the class of the document element
    const currentTheme = document.documentElement.classList.contains('dark');
    setIsDarkMode(currentTheme);

    // Observe changes to the class attribute on the document element
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const updatedTheme = document.documentElement.classList.contains('dark');
          setIsDarkMode(updatedTheme);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    handleCoinChange(selectedValue);
    history.push(`/charts/${selectedValue}`);
  };

  return (
    <div className="form-container">
      <FormControl variant="outlined" style={{ minWidth: 120, marginRight: '10px' }}>
        <InputLabel style={{ color: isDarkMode ? '#ffffff' : '#000000' }}></InputLabel>
        <Select
          value={selectedCoin || ''}
          onChange={handleSelectChange}
          label=""
          displayEmpty
          style={{ color: isDarkMode ? '#ffffff' : '#000000', borderColor: isDarkMode ? '#ffffff' : '#000000' }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)',
                color: isDarkMode ? '#ffffff' : '#000000',
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            <em>Select your coin</em>
          </MenuItem>
          {Object.keys(coinsData.coins).map((coinKey) => {
            const coin = coinsData.coins[coinKey];
            const cleanCoin = coinKey.includes('$') ? coinKey.replace('$', '') : coinKey;
            const logo = logos[cleanCoin] || 'default-logo.png'; // Use default logo if not found
            return (
              <MenuItem key={coinKey} value={coinKey} style={{ height: '40px', display: 'flex', alignItems: 'center' }} className="coin-selection-menu-item">
                <Box display="flex" alignItems="center">
                  <img
                    src={logo}
                    alt={coinKey}
                    style={{ width: '20px', marginRight: '10px' }}
                    onError={(e) => { e.target.onerror = null; e.target.src = 'default-logo.png'; }} // Handle broken image links
                  />
                  <span>{cleanCoin}</span>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CoinSelectionMenu;
