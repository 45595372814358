import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client'; // Import Socket.IO client
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container, TablePagination } from '@mui/material';
import '../styles/TradesData.css';
import { getSocket } from './websocket'; 

const TradesData = ({ coin }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const columns = ['Time', 'Side', 'amountQuote', 'amountBase', 'Price', 'DollarValue'];
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const fetchData = () => {
    axios.get(`${backendUrl}/api/data/trades?coin=${coin}`)
      .then(response => setData(response.data))
      .catch(error => console.error(error));
  };

  console.log("Component trades mounted")

  useEffect(() => {
    console.log("Setting up WebSocket listener...");
    const socket = getSocket()
    fetchData();
    
    console.log("End fetch data...");
    // WebSocket listener for real-time updates
    const handleUpdate = (message) => {
      console.log("WebSocket message received:", message);
      if (message.key.startsWith(`trades:${coin}`)) {
        console.log(`Received trades update for ${coin}, refreshing data...`);
        fetchData(); // Re-fetch data when a trade update is received
      }
    };
  
    socket.on('update', handleUpdate);
    console.log("Socket is on...");
  
    // Cleanup WebSocket listener when component unmounts
    return () => {
      socket.off('update', handleUpdate);  // Remove the specific listener instead of disconnecting
    };
  }, [coin, backendUrl]);

  useEffect(() => {
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <div className="titleContainerTrades">
        <Typography variant="h4" gutterBottom className="titleTrades">
          🔄 Trades
        </Typography>
      </div>
      <TableContainer component={Paper} className="tableContainerTrades">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell key={index} className="tableHeaderTrades">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex} className="tableRowTrades">
                {columns.map((key, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={`tableCellTrades ${key === 'Side' ? row[key] : ''}`}
                  >
                    {row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default TradesData;
