import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Tabs, Tab } from '@mui/material';
import MyLastTrades from './MyLastTrades';
import MyLastOffers from './MyLastOffers';
import OrderBookUser from './OrderBookUser'; // Assume you have an OrderBook component
import MyStats from './MyStats';
import MyGlobalStats from './MyGlobalStats'; // Import the new component
import PriceChartLayout from './PriceChartLayout';
import TradesData from '../TradesData';

const Trading = ({ userId, isVisible, coin }) => {
    const [showChart, setShowChart] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if (isVisible) {
            setShowChart(true);
        }
    }, [isVisible]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container sx={{ padding: '0 !important' }}>
            <Grid container spacing={2} sx={{ margin: '0 !important', width: '100% !important', height: '500px' }}>
                <Grid item xs={12} md={2.4} sx={{ padding: '0 !important', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                        <OrderBookUser coin={coin} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6.6} sx={{ padding: '0 !important', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                        {showChart && <PriceChartLayout coin={coin} />}
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} sx={{ padding: '0 !important', display: 'flex', flexDirection: 'column', height: '500px !important' }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '0px' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="trading tabs">
                            <Tab label="My Trades" />
                            <Tab label="My Current Offers" />
                        </Tabs>
                        <Box
                            sx={{
                                flex: 1,
                                overflowY: 'auto',
                                border: '0px solid #ddd',
                                boxSizing: 'border-box',
                            }}
                        >
                            {tabValue === 0 && <MyLastTrades userId={userId} coin={coin} />}
                            {tabValue === 1 && <MyLastOffers userId={userId} coin={coin} />}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ width: '100% !important', marginTop: '20px', marginBottom: '40px' }}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <MyStats userId={userId} coin={coin}/>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TradesData coin={coin} />
            </Grid>
        </Container>
    );
};

export default Trading;