import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Box } from '@mui/material';
import './styles/UserIdInput.css';

const isValidUserId = (userId) => {
    const clientUuidRegex = /^client\|[0-9a-fA-F]{24}$/;
    return clientUuidRegex.test(userId);
};

const UserIdInput = ({ onSubmit, initialUserId }) => {
    const [inputValue, setInputValue] = useState(initialUserId);
    const [isValid, setIsValid] = useState(true);
    const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

    useEffect(() => {
        console.log('Initial User ID:', initialUserId); // Debugging initial value
        const observer = new MutationObserver(() => {
            setIsDarkMode(document.documentElement.classList.contains('dark'));
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, []);

    const handleChange = (event) => {
        console.log('Changed Value:', event.target.value); // Debugging changes
        setInputValue(event.target.value);
        setIsValid(isValidUserId(event.target.value));
    };

    const handleSubmit = () => {
        console.log('Submitting Value:', inputValue); // Debug before checking
        if (isValidUserId(inputValue)) {
            const uuid = inputValue.includes('|') ? inputValue.split('|')[1] : inputValue;
            console.log('Submitted UUID:', uuid); // Debug the actual value being submitted
            onSubmit(uuid);
        }
    };

    return (
        <Box className={`user-id-input-container ${isDarkMode ? 'dark' : 'light'}`}>
            <TextField
                value={inputValue}
                onChange={handleChange}
                error={!isValid}
                helperText={!isValid ? 'Invalid User ID format' : ''}
                className={`user-id-input ${isDarkMode ? 'dark' : 'light'}`}
                size="small"
                InputProps={{ className: `text-field-input ${isDarkMode ? 'dark' : 'light'}` }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!isValid}
                className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
            >
                Submit
            </Button>
        </Box>
    );
};

UserIdInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialUserId: PropTypes.string.isRequired,
};

export default UserIdInput;
