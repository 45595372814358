import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Container, TextField } from '@mui/material';
import './styles/MyStats.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MyStats = ({ userId, coin }) => {
    const [stats, setStats] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const fetchStatistics = async (userId, coin, startDate, endDate) => {
        try {
            const response = await axios.get(`${backendUrl}/api/data/my_specific_stats`, {
                params: { userId, coin, startDate, endDate },
            });
            setStats(response.data);
        } catch (error) {
            console.error('Error fetching my stats:', error);
        }
    };

    useEffect(() => {
        // Fetch statistics on component mount with default dates
        const today = new Date();
        const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        
        setStartDate(oneMonthAgo.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        
        fetchStatistics(userId, coin, oneMonthAgo.toISOString().split('T')[0], today.toISOString().split('T')[0]);
    }, [userId, coin]);

    useEffect(() => {
        if (startDate && endDate) {
            fetchStatistics(userId, coin, startDate, endDate);
        }
    }, [userId, coin, startDate, endDate]);

    const handleDateChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const orderedStats = [
        `Total GALA Volume`,
        'Net GALA Flow',
        'Avg. Buy Price (GALA)',
        'Number of trades',
        `Net ${coin} Flow`,
        'Avg. Sell Price (GALA)',
    ];

    return (
        <Container className="stats-container">
            <Typography variant="h4" className="titleTrades">
                📈 Statistics
            </Typography>
            <div className="date-picker-container">
                <div className="date-picker">
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={handleDateChange(setStartDate)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={handleDateChange(setEndDate)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </div>
            <Grid container spacing={3}>
                {orderedStats.map((label, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} className="stat-item">
                        <Typography className="stat-label">
                            {label}
                        </Typography>
                        <Typography className="stat-value">
                            {stats[label]}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default MyStats;