import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Grid, ThemeProvider } from '@mui/material';
import theme from '../theme'; // Import your theme file
import PriceChart from './PriceChart';
import OrderBook from './OrderBook';
import TradesData from './TradesData';
import StatisticsGeneral from './StatisticsGeneral';
import StatisticsTrades from './StatisticsTrades';
import LeaderboardHoldersData from './LeaderboardHoldersData';
import LeaderboardTradersData from './LeaderboardTradersData';
import CoinSelectionMenu from './CoinSelectionMenu'; // Import the new component

const Dashboard = () => {
    const { coin } = useParams();
    const [selectedCoin, setSelectedCoin] = useState(coin);

    useEffect(() => {
        setSelectedCoin(coin);
    }, [coin]);

    
    const handleCoinChange = (newCoin) => {
        setSelectedCoin(newCoin);
    };



    return (
        <ThemeProvider theme={theme}>
            <div className="min-h-screen bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 p-6">
                <Container style={{ marginTop: '24px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} style={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
                            <PriceChart coin={selectedCoin} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
                            <OrderBook coin={selectedCoin} />
                        </Grid>
                        <Grid item xs={12} md={9} style={{ display: 'flex', flexDirection: 'column',marginTop: '24px' }}>
                            <StatisticsGeneral coin={selectedCoin} />
                            <StatisticsTrades coin={selectedCoin} style={{ marginTop: '24px' }} />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', marginLeft:'-50px',marginTop:'40px'}}>
                            {/* <CoinSelectionMenu selectedCoin={selectedCoin} handleCoinChange={handleCoinChange} /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <TradesData coin={selectedCoin} />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <LeaderboardHoldersData coin={selectedCoin} />
                        </Grid>
                        {selectedCoin === 'WEN' && (
                            <>
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <LeaderboardTradersData coin={selectedCoin} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Container>
            </div>
        </ThemeProvider>
    );
};

export default Dashboard;
