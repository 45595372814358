import { io } from "socket.io-client";

let socket;

export const getSocket = () => {
    if (!socket) {
        socket = io("https://galacoins.xyz", { // Updated to your production URL with wss
            transports: ["websocket"]
        });
    }
    return socket;
};
