import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, ButtonGroup, Button } from '@mui/material';
import iconSwitch from '../../assets/icons/light/icon_switch.png'; // Import your switch icon
import coinsData from '../../assets/listed_coins.json'; // Import the JSON data
import logoMap from '../logos'; // Adjust the path as needed
import { formatPrice } from '../../utils/formatPrice'; // Adjust the path as needed
import PriceChart from './PriceChart'; // Adjust the path as needed
import './styles/PriceChartLayout.css'; // Import the CSS file

const PriceChartLayout = ({ coin }) => {
    const [timeframe, setTimeframe] = useState('1H');
    const [latestPrice, setLatestPrice] = useState(null);
    const [priceUnit, setPriceUnit] = useState('GALA'); // State to manage the price unit
    const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

    const coinData = coinsData.coins[coin];
    const cleanCoin = coin.includes('$') ? coin.replace('$', '') : coin;
    const handlePriceUnitToggle = () => {
        setPriceUnit(priceUnit === 'GALA' ? 'USD' : 'GALA');
    };

    const handleTimeframeChange = (newTimeframe) => {
        setTimeframe(newTimeframe);
    };

    useEffect(() => {
        const observer = new MutationObserver(() => {
            setIsDarkMode(document.documentElement.classList.contains('dark'));
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, []);

    return (
        <Box className={`price-chart-layout ${isDarkMode ? 'dark' : ''}`}>
            <div className="price-chart-header">
                <img src={logoMap[cleanCoin]} alt={`${cleanCoin.toUpperCase()} Logo`} className="price-chart-logo" />
                <Typography variant="h5" className="price-chart-title">
                    {cleanCoin}
                </Typography>
            </div>
            <div className="price-chart-buttons">
                <ButtonGroup variant="contained" color="primary">
                    <Button onClick={() => handleTimeframeChange('15min')} className="price-chart-button">15m</Button>
                    <Button onClick={() => handleTimeframeChange('1H')} className="price-chart-button">1H</Button>
                    <Button onClick={() => handleTimeframeChange('4H')} className="price-chart-button">4H</Button>
                    <Button onClick={() => handleTimeframeChange('1D')} className="price-chart-button">1D</Button>
                    <Button onClick={() => handleTimeframeChange('1W')} className="price-chart-button">1W</Button>
                </ButtonGroup>
            </div>
            <div className="price-chart-price">
                {latestPrice !== null && (
                    <div className="price-chart-label">
                        {formatPrice(latestPrice)} ${priceUnit}
                        <IconButton onClick={handlePriceUnitToggle} className="price-chart-switch-button">
                            <img src={iconSwitch} alt="Switch Icon" className="price-chart-switch-icon" />
                        </IconButton>
                    </div>
                )}
            </div>
            <Box className="price-chart-box">
                <PriceChart coin={coin} timeframe={timeframe} priceUnit={priceUnit} onPriceUpdate={setLatestPrice} onTimeframeChange={handleTimeframeChange} />
            </Box>
        </Box>
    );
};

export default PriceChartLayout;
