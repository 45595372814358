import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client'; // Import Socket.IO client
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box } from '@mui/material';
import { formatPrice } from '../../utils/formatPrice'; // Ensure this is the correct path
import './styles/MyLastOffers.css'; // Import the CSS file
import { getSocket } from '../websocket'; 

const MyLastOffers = ({ userId, coin }) => {  // Accept userId and coin as props
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = ['TIME', 'ACTION', 'Price', `QTY`, 'FILL %'];
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const fetchData = () => {
    axios.get(`${backendUrl}/api/data/my_last_offers`, { params: { userId, coin } })
      .then(response => setData(response.data))
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchData();
    const socket = getSocket();
    // WebSocket listener for real-time updates
    const handleUpdate = (message) => {
      if (message.key === `offers:${coin}:${userId}`) {
        console.log(`Received update for offers of user ${userId} for ${coin}, refreshing data...`);
        fetchData(); // Re-fetch data when offers are updated
      }
    };

    socket.on('update', handleUpdate);
    // Cleanup WebSocket connection when component unmounts
    return () => {
      socket.off('update', handleUpdate);
    };
  }, [userId, coin]); // Re-fetch data when userId or coin changes

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatCell = (key, value) => {
    if (key === 'Price' || key === `QTY`) {
      return formatPrice(value);
    }
    return value;
  };

  return (
    <Box className="my-last-offers-container">
      <TableContainer component={Paper} className="my-last-offers-table-container">
        <Table className="my-last-offers-table">
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell key={index} className="my-last-offers-table-head-cell">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex} className="my-last-offers-table-row">
                {columns.map((key, cellIndex) => (
                  <TableCell key={cellIndex} className={`my-last-offers-table-cell ${key === 'ACTION' && (row[key] === 'BUY' ? 'my-last-offers-buy-cell' : row[key] === 'SELL' ? 'my-last-offers-sell-cell' : '')}`}>
                    {formatCell(key, row[key])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default MyLastOffers;
