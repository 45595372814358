import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import io from 'socket.io-client'; // Import Socket.IO client
import { getSocket } from './websocket'; 

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles(() => ({
    statsContainer: {
        marginTop: '20px',
    },
    statItem: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    statValue: {
        fontSize: '1.3rem !important',
        fontWeight: 'normal',
    },
    statLabel: {
        fontSize: '0.8rem !important',
        color: '#777',
    },
}));

const StatisticsTrades = ({ coin }) => {
    const [stats, setStats] = useState({});
    const classes = useStyles();

    const fetchStatistics = async () => {
        try {
            const response = await axios.get(`${backendUrl}/api/data/statistics_trades?coin=${coin}`);
            setStats(response.data);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        }
    };
    
    useEffect(() => {
        fetchStatistics(); // Initial fetch
        const socket = getSocket();
        
        // WebSocket listener for real-time updates
        const handleUpdate = (message) => {
            if (message.key.startsWith(`trades:${coin}`)) {
                console.log(`Received update for statistics trades of ${coin}, refreshing data...`);
                fetchStatistics(); // Re-fetch statistics when an update is received
            }
        };
    
        socket.on('update', handleUpdate);
    
        // Clean up event listener when the component unmounts or `coin` changes
        return () => {
            socket.off('update', handleUpdate); // Clean up the specific event listener
        };
    }, [coin]); // Re-fetch when `coin` changes
    
    const orderedStats = [
        'Daily Trades',
        'Daily Traders',
        'Daily Volume ($GALA)',
    ];

    return (
        <Container className={classes.statsContainer}>
            <Grid container spacing={3}>
                {orderedStats.map((label, index) => (
                    <Grid item xs={6} md={3} key={index} className={classes.statItem}>
                        <Typography className={classes.statLabel}>
                            {label}
                        </Typography>
                        <Typography className={classes.statValue}>
                            {stats[label]}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default StatisticsTrades;
