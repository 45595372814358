import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFC600', // Primary Yellow
    },
    secondary: {
      main: '#00BFFF', // Secondary Blue
    },
    error: {
      main: '#FF4500', // Accent Red
    },
    warning: {
      main: '#FF1493', // Accent Pink
    },
    success: {
      main: '#32CD32', // Accent Green
    },
    text: {
      primary: '#000000', // Dark Blue for Text
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightBold: 700,
  },
});

export default theme;
